var stickyHeader = true;
// var transformHomepageDonationAmountImages = true;
var flyoutNav = true;
var hoverSensitiveNav = true;
// var fullscreenNav = false;
// var homeQuickGivingPreFooter = false;
var countUpStats = true;
var countUpStatsDuration = 3000;

// var subsiteHijackMainNav = ['example-subsite-name'];
// var subsiteHijackMainLogoClickDestination = ['example-subsite-name'];
// var carouselBackgroundVideoYouTubeID = ''; // see https://fresco-docs.netlify.com/#/carousel/background-video
// var feedTitleLinks = ['/first-feed-title-link', '/second-feed-title-link']; // see https://fresco-docs.netlify.com/#/home-feeds/feed-title-links
// var feedItemsCarouselBreakpoint = 576; // see https://fresco-docs.netlify.com/#/home-feeds/feed-items-carousel
// var feedItemsCarouselAutoplay = true;
// var feedItemsCarouselAutoplaySpeed = 4000;


// Feeds - See more button
$('<a class="feedSeeAll cta-button" href="/pages/category/homepage-user-storiesy">Read more stories</a>').insertBefore('.UserstoriesFeed .feedList');
$('<a class="feedSeeAll cta-button" href="/pages/news/">See all news</a>').insertBefore('.homeFeed.homeFeednews .feedList');


// Move blog feed
if ($('.homeFeed').hasClass("YourstoriesFeed")) {
  $('.YourstoriesFeed').insertAfter('.homeFeaturesWrapper .homeFeatures');
  $('.homefeaturecategory-homeboximpactstats').addClass('impactstatsAfterStoties');
};